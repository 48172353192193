<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }} 
                </div>
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  {{ userData.userId }} - {{ userData.user_name }} {{ userData.email }}
                  {{ userData.salutation }}  {{ userData.initials }} {{ userData.title }} {{ userData.first_name }} {{ userData.insertion }} {{ userData.last_name }}
                </div>
                <div class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  <a v-if="companyUserDetails" :href='`${SITE_ROOT}/companies/${companyData.companies_id}/users/${companyUserDetails.company_user_id}/profile/${userData.userId}`' target='_blank'>waarneemapp.nl</a>
                </div>
              </div>
            </div>
        </div>
      </div>
        
      
      <div class="columns is-mobile is-gapless is-centered is-multiline m2rem">
        <div class="column is-2">
          <h1>blockedDays ({{ blockedDaysData ? blockedDaysData.length : '' }}) userId:{{ userId }}</h1>
          <b-table
              :data="blockedDaysData"
              :bordered="true"
              :striped="true"
              :narrowed="true"
              :loading="tableBlockedDaysIsLoading"
              :mobile-cards="true">

              <b-table-column field="wa_blocks_date" label="date" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                  {{ props.row.wa_blocks_date ? DDMMYYYY_HHii(new Date(props.row.wa_blocks_date )) : '' }}
              </b-table-column>

              <template #empty>
                  <div class="has-text-centered">No records</div>
              </template>

          </b-table>
        </div>
        
        
        <div class="column is-3 is-offset-1">
          <h1>UserDetails</h1>
          <b-table
            :data="UserDetailsData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableUserDetailsIsLoading"
            :mobile-cards="true">

            <b-table-column field="name" label="key" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="value" label="value" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.value }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        </div>
        
        
        <div class="column is-3 is-offset-1">
          <h1>Code Preferences</h1>
          <b-table
            :data="codePrefData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableCodePrefIsLoading"
            :mobile-cards="true">

            <b-table-column field="code_id" label="code id" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.code_id }}
            </b-table-column>
            <b-table-column field="codes_code" label="code" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.codes_code }}
            </b-table-column>
            <b-table-column field="codes_name" label="name" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.codes_name }}
            </b-table-column>
            <b-table-column field="max_shifts" label="max_shifts" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.max_shifts }}
            </b-table-column>
            <b-table-column field="min_shifts" label="min_shifts" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.min_shifts }}
            </b-table-column>
            <b-table-column field="ignore_available" label="ignore available" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.ignore_available }}
            </b-table-column>
            <b-table-column field="updated_at" label="updated_at" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.updated_at ? DDMMYYYY_HHii(new Date(props.row.updated_at )) : '' }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        </div>
        
        
        
        
      </div>
      
        
        
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>ResponseData userId:{{ userId }}</h1>
        <b-button @click="ToggleLatestOnly" :type="showLatestOnly === true ? 'is-success' : ''" >
          LatestOnly
        </b-button>
        <b-button @click="toggleAssignedOnly" :type="showAssignedOnly === true ? 'is-success' : ''">
          showAssignedOnly
        </b-button>
        <b-table
            :data="responseDataFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableResponseIsLoading"
            :mobile-cards="true">

            <b-table-column field="wa_response_statuses_updated_at" label="updatedAt" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_response_statuses_updated_at ? DDMMYYYY_HHii(new Date(props.row.wa_response_statuses_updated_at )) : '' }}
            </b-table-column>
            <b-table-column field="wa_code_schedule_schedule_id" label="scheduleId" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_schedule_id }}
            </b-table-column>

            <b-table-column field="wa_code_schedule_code_id" label="codeId" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_code_schedule_code_id }}
            </b-table-column>
            
            <b-table-column field="wa_codes_code" label="code" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_codes_code }}
            </b-table-column>
            
            <b-table-column field="wa_codes_name" label="name" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.wa_codes_name }}
            </b-table-column>
            
            <b-table-column field="wa_vacancies_id" label="vacanciesId" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
              <a :href='`${WEB_ROOT}/company/${companyId}/vacancy/${props.row.wa_vacancies_id}`' target='_blank' class="highlight">
                {{ props.row.wa_vacancies_id }}
              </a>
            </b-table-column>
            <b-table-column field="wa_shifts_id" label="shift id" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
              {{ props.row.wa_shifts_id }}              
            </b-table-column>
            
            <b-table-column field="wa_shifts_date" label="date" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_shifts_date ? DDMMYYYY_HHii(new Date(props.row.wa_shifts_date )) : '' }}
            </b-table-column>
            
            <b-table-column field="wa_shifts_start_time" label="start" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_shifts_start_time}}
            </b-table-column>
            
            <b-table-column field="wa_shifts_end_time" label="end" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{ props.row.wa_shifts_end_time}}
            </b-table-column>
            
            <b-table-column field="duration" label="duration" width="40" :td-attrs="columnTdAttrs" date v-slot="props" sortable centered searchable>
                {{ props.row.duration }}
            </b-table-column>
            
            <b-table-column field="wa_shifts_price" label="price" width="40" :td-attrs="columnTdAttrs" date v-slot="props" centered sortable searchable>
                {{ props.row.wa_shifts_price }}
            </b-table-column>

            <b-table-column field="wa_code_schedule_exclude_quotum" label="exclude_quotum" width="40" :td-attrs="columnTdAttrs" date v-slot="props" string sortable centered searchable>
                {{ props.row.wa_code_schedule_exclude_quotum }}
            </b-table-column>
            
            <b-table-column field="wa_code_schedule_exclude_min_max" label="minmax" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable centered searchable>
                {{ props.row.wa_code_schedule_exclude_min_max }}
            </b-table-column>
            
            <b-table-column field="status" label="status" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.status }}
            </b-table-column>
            
            <b-table-column field="wa_responses_id" label="responsesId" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_responses_id  }}
            </b-table-column>
            <b-table-column field="wa_response_statuses_id" label="responseStatusesId" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.wa_response_statuses_id  }}
            </b-table-column>            
            <b-table-column field="LatestResponseStatusId" label="LatestResponseStatusId" width="40" :td-attrs="columnTdAttrs" v-slot="props" sortable centered searchable>
                {{ props.row.LatestResponseStatusId  }}
            </b-table-column>
            
            
            

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
        
        <div class="columns is-multiline is-marginless">
            <div class="column is-12">Totals</div>
            <div class="column is-2">rows: {{ totalsResponses.count }}</div>
            <div class="column is-2">cancelled: {{ totalsResponses.canceled }}</div>
            <div class="column is-2">assigned: {{ totalsResponses.assigned }}</div>
            <div class="column is-2">assigned Hours: {{ totalsResponses.assignedHours }}</div>
            <div class="column is-2">assigned_exQuotem: {{ totalsResponses.assigned_exQuotem }}</div>
            <div class="column is-2">assigned_exQuotemHours: {{ totalsResponses.assigned_exQuotemHours }}</div>
        </div>
        
      </div>
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii } from '@/helpers/dates.js';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          userId              : null,
          SITE_ROOT     : process.env.VUE_APP_SITE_MAIN,
          
          companyData         : [],
          userData            : [],
          
          blockedDaysData     : [],
          tableBlockedDaysIsLoading : false,
          companyUserDetails  : null,
          
          UserDetailsData     : [],
          tableCodePrefIsLoading : false,
          
          codePrefData     : [],
          tableUserDetailsIsLoading : false,
          
          responseData            : [],
          responseDataFiltered    : [],
          tableResponseIsLoading  : false,
          showAssignedOnly        : true,
          showLatestOnly          : true,
          
          totalsResponses : {
            count: 0,
            canceled: 0,
            assigned: 0,
            assignedHours: 0,
            assigned_exQuotem: 0,
            assigned_exQuotemHours: 0,
          },

          
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.userId = this.$route.params.userId;
        this.ShowPageLoading(15000)
        this.getCompanyData()
      },
      methods: {
        DDMMYYYY_HHii,
        async getCompanyData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/user/${this.userId}`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          console.info(`response:`, response)
          this.companyData = response.data.payload.companyData
          this.userData = response.data.payload.userData[0]
          this.blockedDaysData = response.data.payload.blockedDays
          this.responseData = response.data.payload.responseStatusData
          
          let userDetails = []
          for(let key in response.data.payload.companyUserDetails) {
            userDetails.push({
              name: key,
              value: response.data.payload.companyUserDetails[key]
            })
          }
          this.companyUserDetails = response.data.payload.companyUserDetails
          console.info(`this.companyUserDetails:`, this.companyUserDetails)
          this.UserDetailsData = userDetails
          console.info(`this.UserDetailsData:`, this.UserDetailsData)
          
          this.codePrefData = response.data.payload.shiftPreferences
          
          this.FilterResponseData()
          
          this.HidePageLoading()
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        FilterResponseData(){
          let list = []
          let totals = {
            count: 0,
            canceled: 0,
            assigned: 0,
            assignedHours: 0,
            assigned_exQuotem: 0,
            assigned_exQuotemHours: 0,
          }
          
          for(let data of this.responseData) {
            // console.info(`data:`, data); break
            if (this.showLatestOnly === true && data.wa_response_statuses_id !== data.LatestResponseStatusId) continue
            if (this.showAssignedOnly === true && ((data.status !== "assigned" && data.status !== "delegated") || (data.wa_response_statuses_id !== data.LatestResponseStatusId))) continue
            
            
            let vDate = this.DDMMYYYY(new Date(data.wa_shifts_date));
            let shiftstats = this.calcDuration(vDate, data.wa_shifts_start_time, data.wa_shifts_end_time) 
            data.duration = shiftstats.durationSec / 3600
            
            totals.count += 1;
            if (data.status === "cancelled") {
              totals.canceled += 1
            }
            if (data.status === "assigned" || data.status === "delegated") {
              totals.assigned += 1
              totals.assignedHours += data.duration
              if (data.wa_code_schedule_exclude_quotum !== 1) {
                totals.assigned_exQuotem += 1
                totals.assigned_exQuotemHours += data.duration
              }
            }
            
            
            list.push(data)
          }
          // console.info(`list`, list)
          console.info(`totals`, totals)
          this.totalsResponses = totals
          this.responseDataFiltered = list
        },
        
        ToggleLatestOnly(){
          this.showLatestOnly = !this.showLatestOnly
          // if (this.showLatestOnly === false) {
          //   this.showAssignedOnly = false
          // }
          this.FilterResponseData()
        },
        
        toggleAssignedOnly(){
          this.showAssignedOnly = !this.showAssignedOnly
          // if (this.showAssignedOnly === true) {
          //   this.showLatestOnly = true
          // }
          this.FilterResponseData()
        },   
        
        DDMMYYYY(d = new Date()){
          return this.DoubleDigits(d.getDate()) + "-" + this.DoubleDigits(d.getMonth() +1) +  "-" + d.getFullYear();
        },
        
        DoubleDigits (number) {
          if (parseInt(number) < 10) {
            return "0" + number;
          }
          return number;
        },
        
        calcDuration(dateStr, start_time, end_time){
          const d = dateStr.split('-')
          const day = parseInt(d[0])
          const month = parseInt(d[1]-1)
          const year = parseInt(d[2])
          
          const ts = start_time.split(':')
          const tsHours = parseInt(ts[0])
          const tsMinutes = parseInt(ts[1])
          const tsSeconds = parseInt(ts[2])
          const startDate = new Date(year,month,day,tsHours,tsMinutes,tsSeconds)
          
          const te = end_time.split(':')
          const teHours = parseInt(te[0])
          const teMinutes = parseInt(te[1])
          const teSeconds = parseInt(te[2])
          const endDate = new Date(year,month,day,teHours,teMinutes,teSeconds)
          
          if (endDate.getTime() <= startDate.getTime()) {
            endDate.setDate(endDate.getDate() + 1)
          }
            
          const durationSec = (endDate.getTime() - startDate.getTime()) / 1000
          
          return {
            startDate,
            endDate,
            durationSec    
          }
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
      }
    }
</script>

<style scoped>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
</style>